import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

const cleanExtraSpaces = (prevState: EditorState, currentState: EditorState): EditorState => {
  const prevHtml = editorStateToHtml(prevState)
  const currentHtml = editorStateToHtml(currentState)
  const cleanCurrentHtml = cleanHtml(currentHtml, prevHtml)
  const newEditor = htmlToEditorState(cleanCurrentHtml)
  if (newEditor) return newEditor
  return currentState
}

export const cleanHtml = (currentHtml: string, oldHtml: string): string => {
  let before = ''
  let after = ''
  let beforeIndex = 0

  // Get equal content before the new link
  while (beforeIndex < currentHtml.length && oldHtml.at(beforeIndex) === currentHtml.at(beforeIndex)) {
    beforeIndex++
  }

  before = currentHtml.slice(0, beforeIndex)

  let oldAfterIndex = oldHtml.length - 1
  let currentAfterIndex = currentHtml.length - 1

  // Get equal content after the new link
  while (
    oldAfterIndex >= beforeIndex &&
    currentAfterIndex >= beforeIndex &&
    oldHtml.at(oldAfterIndex) === currentHtml.at(currentAfterIndex)
  ) {
    oldAfterIndex--
    currentAfterIndex--
  }

  after = currentHtml.slice(currentAfterIndex + 1)

  let diffHtml = currentHtml.slice(beforeIndex, currentAfterIndex)

  diffHtml = diffHtml.replace(/(<\/a>)([^\s]*)\s/, '$1$2')

  // Join the string before the link, the new link without extra space, and the string after the link
  return [before, diffHtml, after].join('')
}

const editorStateToHtml = (editorState: EditorState): string => {
  const contentState = editorState.getCurrentContent()
  const rawContent = convertToRaw(contentState)
  const html = draftToHtml(rawContent)
  return html
}

const htmlToEditorState = (html: string): EditorState | null => {
  const contentBlock = htmlToDraft(html)
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
    return EditorState.createWithContent(contentState)
  }
  return null
}

export const getLinkCount = (editorState: EditorState): number => {
  const html = editorStateToHtml(editorState)
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const links = doc.querySelectorAll('a')
  return links.length
}

export const getCleanEditorState = (prevEditorState: EditorState, newEditorState: EditorState) => {
  const prevStateLinkCount = getLinkCount(prevEditorState)
  const newStateLinkCount = getLinkCount(newEditorState)
  if (newStateLinkCount > prevStateLinkCount) {
    return cleanExtraSpaces(prevEditorState, newEditorState)
  } else {
    return newEditorState
  }
}
