export enum WarningMessagesTitles {
  WARNING = 'Warning',
  ERROR = 'Error',
  ASSIGN = 'Assign',
  TRANSFER = 'Transfer',
  UPLOAD_ERROR = 'Upload Error',
  SCHOOL_OF_ENROLLMENT = 'School of Enrollment',
  SCHOOL_YEAR_DELETION = 'School Year Deletion',
  NOTIFICATION_SETTINGS = 'Notification Settings',
  UPDATE_INTERVENTION_STATUS = 'Update Intervention Status',
}

export enum WarningMessagesSubtitles {
  SELECT_APPLICATION = 'Please select applications',
  NO_STUDENT_SELECTED = 'No student(s) selected',
  SELECTED_STUDENTS_DELETION = 'About to delete selected applications.',
  REQUESTS_EXCEEDED = 'This student has used the maximum number of requests.',
  STUDENT_ALREADY_ASSIGNED = 'This student(s) has already been assigned to a Homeroom.',
  PLEASE_TRANSFER = 'Please transfer the student instead.',
  STUDENT_NOT_ASSIGNED = 'At least one student was not assigned.',
  SIBLING_IN_MULTIPLE_HOMEROOMS = 'Siblings are in multiple homerooms or siblings have no homeroom assignment',
  PAST_DUE_DATE = 'This Homeroom has Learning Logs past their due date.',
  PROCEED = 'How would you like to proceed?',
  NOT_TRANSFERED = 'This student(s) has not been assigned to a Homeroom.',
  PLEASE_ASSIGN = 'Please assign the student instead.',
  NO_HOMEROOM = 'No Homeroom selected',
  NO_RECEIPTS = 'At least one receipt is required',
  LL_TRANSFER = 'All Weekly Learning Log history and grades will be',
  LL_TRANSFER_CONTD = 'transferred.',
  UPLOAD_ERROR = 'An error occurred during the upload process. Please try again.',
  SAVING_ERROR = 'An error occurred during the saving process. Please try again.',
  POPUP_OVERLAP = 'Unable to save. The selected dates overlap with existing announcements. Please choose different start and/or end dates to ensure only one announcement is displayed at a time.',
  SOE_TRANSFER = 'How would you like to proceed with the SOE change?',
  DOWNLOAD_LEARNING_LOG = 'Failed to download learning log file',
  UNOFFICIAL_TRANSCRIPT = 'Failed to download unofficial transcript',
  DOWNLOAD_OPT_OUT_FORM = 'Failed to download opt-out form',
  SUBMIT_LEARNING_LOG = 'An error occurred while submitting the learning log',
  LOADING_LEARNING_LOG = 'Something went wrong while loading the learning log. Please try again.',
  SCHOOL_YEAR_HAS_RELATED_DATA = 'This school year cannot be deleted because it has related data.',
  SUBMIT_RESOURCE_REQUEST = 'To complete your request, please click the → button at the Submit Request bar. You will be taken to the Submit Request page.',
  SUBMIT_RESOURCE_REQUEST_2 = 'On the Submit Request page, please click the black Submit button.',
  PARTICIPATION_SETTINGS = 'Would you like to receive the 4pm reminder via text message (SMS), email, or both?',
  NO_PHONE_NUMBER = 'You do not have a phone number on file to receive text messages.',
  SCHEDULE_SUBMISSION_ERROR = 'InfoCenter has encountered an error. Your period selections have not been saved. Please reselect your student’s periods and try again.',
  ANNOUNCEMENTS_UNSUPPORTED_INSERTS = 'Announcements do not support email inserts.',
}

export enum WarningMessagesButtons {
  CLOSE = 'Close',
  OK = 'OK',
  ASSIGN = 'Assign',
  CANCEL = 'Cancel',
  TRANSFER = 'Transfer',
  SAVE = 'Save',
  CONTINUE = 'Continue',
}
