import { gql } from '@apollo/client'

export const createPersonInterventionMutation = gql`
  mutation CreatePersonIntervention($createPersonInterventionInput: CreatePersonInterventionInput!) {
    createPersonIntervention(createPersonInterventionInput: $createPersonInterventionInput) {
      student_id
      school_year_id
      homeroom_id
      intervention_status
    }
  }
`
