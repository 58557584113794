import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Card, Snackbar } from '@mui/material'
import { useFlag } from '@unleash/proxy-client-react'
import { startOfDay } from 'date-fns'
import { isNil, map } from 'lodash'
import { useRecoilState } from 'recoil'
import { CustomModal } from '@mth/components/CustomModal/CustomModals'
import {
  EMPTY_STRING,
  EPIC_275_STORY_5395,
  EPIC_275_STORY_5565,
  EPIC_275_STORY_6022,
  EPIC_275_TASK_6023,
  HYPHEN,
} from '@mth/constants'
import { MthLoadingWrapper } from '@mth/core/components/shared/MthLoadingWrapper/MthLoadingWrapper'
import {
  EnrollmentPacketSaveAction,
  EnrollmentPacketSaveContext,
  MthColor,
  WithdrawalOption,
  WithdrawalStatus,
  RoleLabels,
  StudentStatus,
  ScheduleStatus,
  StudentStatusLabel,
  InactiveOption,
  soeLowStatus,
  MthTitle,
} from '@mth/enums'
import { saveWithdrawalMutation } from '@mth/graphql/mutation/withdrawal'
import { submitDiplomaAnswerGql } from '@mth/graphql/queries/diploma'
import { useCurrentSchoolYearByRegionId, useNextSchoolYearByRegionId, useReload, useSoeOperations } from '@mth/hooks'
import { useNotificationSettings } from '@mth/hooks/useNotificationSettings/useNotificationSettings'
import { Address, Phone, SchoolYear, Student } from '@mth/models'
import { alertState } from '@mth/providers/Store/State'
import { UserContext } from '@mth/providers/UserContext/UserProvider'
import { Header } from './components/Header/Header'
import { NewUserModal } from './components/NewUserModal/NewUserModal'
import { Students } from './components/Students/Students'
import { ParentProfile } from './ParentProfile/ParentProfile'
import {
  DeleteWithdrawal,
  getParentDetail,
  updatePersonAddressMutation,
  updateStudentsAddressMutation,
  UpdateStudentMutation,
  getStudentDetail,
  sendInactiveStatusEmail,
} from './services'
import { StudentProfile } from './StudentProfile/StudentProfile'
import { ScheduleInfo, StudentTemp } from './StudentProfile/types'
import { useStyles } from './styles'
import { AddressProfile, PersonProfile, PhoneProfile, UserProfileProps } from './types'
import { stripHtmlTags } from './utils'
import { approveApplicationMutation, updateScheduleStatus } from '../Applications/services'
import { savePacketMutation } from '../EnrollmentPackets/services'

export const UserProfile: React.FC<UserProfileProps> = ({
  handleClose,
  role,
  student_id,
  parent_id,
  setIsChanged,
  isChanged,
}) => {
  const classes = useStyles
  const epic275story5395 = useFlag(EPIC_275_STORY_5395)
  const epic275Story5565 = useFlag(EPIC_275_STORY_5565)
  const epic275Story6022 = useFlag(EPIC_275_STORY_6022)
  const epic275TASK6023 = useFlag(EPIC_275_TASK_6023)
  const [loading, setLoading] = useState(false)
  const [userInfo, setUserInfo] = useState<unknown>()
  const [phoneInfo, setPhoneInfo] = useState<unknown>()
  const [addresInfo, setAddressInfo] = useState<unknown>()
  const [parentEmail, setParentEmail] = useState()
  const [students, setStudents] = useState([])
  const [observers, setObservers] = useState([])
  const [notes, setNotes] = useState(EMPTY_STRING)
  const [studentNotes, setStudentNotes] = useState<string>(EMPTY_STRING)
  const [initialStudentNotes, setInitialStudentNotes] = useState<string>(EMPTY_STRING)
  const [initialStudentNotesSeted, setInitialStudentNotesSeted] = useState<boolean>(false)
  const [confirmLeaveWithouthSave, setConfirmLeaveWithouthSave] = useState<boolean>(false)
  const [studentToMove, setStudentToMove] = useState<Student>()
  const [studentPerson, setStudentPerson] = useState<unknown>()
  const [openObserverModal, setOpenObserverModal] = useState(false)
  const [student, setStudent] = useState<Student | undefined>()
  const [studentSchoolYears, setStudentSchoolYears] = useState<SchoolYear[]>([])
  const [actualSchedule, setActualSchedule] = useState<ScheduleInfo>()
  const [studentStatus, setStudentStatus] = useState<StudentTemp>({
    student_id: 0,
    special_ed: 0,
    diploma_seeking: 0,
    testing_preference: EMPTY_STRING,
    status: 0,
    date: EMPTY_STRING,
    school_year_id: 0,
    birth: EMPTY_STRING,
  })
  const [selectedParent, setSelectedParent] = useState(0)
  const [userRole, setUserRole] = useState<string>(RoleLabels.PARENT)
  const [selectedStudent, setSelectedStudent] = useState(student_id)
  const [applicationState, setApplicationState] = useState(EMPTY_STRING)
  const [studentOldStatus, setStudentOldStatus] = useState(0)
  const [requesting, setRequesting] = useState<boolean>(false)
  const { me } = useContext(UserContext)
  //remove when epic275Story5565 is removed
  const [errorFlag, setErrorFlag] = useState<boolean>(false)
  const [, setAlertSetting] = useRecoilState(alertState)
  const [updateStudent] = useMutation(UpdateStudentMutation)
  const [createWithdrawal] = useMutation(saveWithdrawalMutation)
  const [deleteWithdrawal] = useMutation(DeleteWithdrawal)
  const [sendInactiveEmailUpdate] = useMutation(sendInactiveStatusEmail)
  const [saveDiplomaAnswer] = useMutation(submitDiplomaAnswerGql)
  const [isEditedByExternal, setIsEditedByExternal] = useState<boolean>(false)
  const { data: schoolYear } = useCurrentSchoolYearByRegionId(Number(me?.selectedRegionId))
  const { data: nextSchoolYear } = useNextSchoolYearByRegionId(Number(me?.selectedRegionId))
  const [isFromSave, setIsFromSave] = useState<boolean>(false)

  const [schoolPartnerId, setSchoolPartnerId] = useState(soeLowStatus.UNASSIGNED.toString())
  const [schoolPartnerIdInitial, setSchoolPartnerIdInitial] = useState(soeLowStatus.UNASSIGNED.toString())
  const [soeChange, setSoeChange] = useState(false)
  const [isTransfer, setIsTransfer] = useState(false)
  const [generateTransferForm, setGenerateTransferForm] = useState(false)
  const { transferStudentsSOE, assignStudentsSOE } = useSoeOperations()

  const [reload, reloading] = useReload()
  const {
    notificationSettings,
    selectedSetting,
    setSelectedSetting,
    phoneAvailability,
    phoneAvailableLoading,
    saveNotificationSetting,
    loadingSaveNotificationSettings,
    notificationSettingLoading,
  } = useNotificationSettings(
    typeof userInfo === 'object' && userInfo && 'person_id' in userInfo ? Number(userInfo.person_id) : 0,
  )

  const assingStudentInitialNotes = (initialNotes: string) => {
    setStudentNotes(initialNotes)
    setInitialStudentNotes(initialNotes)
  }

  const [updatePersonAddress] = useMutation(updatePersonAddressMutation)
  const [updateStudentsAddress] = useMutation(updateStudentsAddressMutation, {
    errorPolicy: 'all',
    onError() {
      if (epic275Story5565) {
        setAlertSetting({
          type: 'error',
          message: 'Error updating student address',
          show: true,
        })
      } else setErrorFlag(true)
    },
  })
  const { data: currentUserData, refetch } = useQuery(getParentDetail, {
    variables: {
      id: Number(parent_id),
    },
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    if (role === RoleLabels.STUDENT) {
      setUserRole(RoleLabels.STUDENT)
    } else if (role !== RoleLabels.STUDENT && role !== RoleLabels.PARENT) {
      setUserRole(RoleLabels.OBSERVER)
    }
  }, [])

  const getStudentNotesForParent = (studentsList = students) => {
    let notes = ''
    studentsList.forEach((student: Student) => {
      const noHtmlNotes = stripHtmlTags(student?.admin_notes)
      if (noHtmlNotes !== EMPTY_STRING) {
        notes += ` <p><strong>${student?.person.first_name} ${HYPHEN} </strong>${noHtmlNotes}</p><br>`
      }
    })
    return notes
  }

  useEffect(() => {
    if (currentUserData) {
      const studentsWithApplicationNotRemoved =
        currentUserData.parentDetail.students.filter(
          // @ts-ignore
          (student) =>
            // @ts-ignore
            !student?.applications?.every((application) => application?.status === StudentStatusLabel.DELETED),
        ) ?? currentUserData

      setUserInfo(currentUserData.parentDetail.person)
      setStudents(studentsWithApplicationNotRemoved)
      setPhoneInfo(currentUserData.parentDetail.phone)
      setAddressInfo(currentUserData.parentDetail.person.address)
      setNotes(currentUserData.parentDetail.notes)
      setParentEmail(currentUserData.parentDetail.person.email)
      setObservers(currentUserData.parentDetail.observers)
      if (isFromSave) {
        setIsFromSave(false)
      } else if (!student_id) {
        if (epic275story5395) {
          if (epic275TASK6023) {
            assingStudentInitialNotes(getStudentNotesForParent(studentsWithApplicationNotRemoved))
          } else {
            setStudentNotes(getStudentNotesForParent(studentsWithApplicationNotRemoved))
          }
        }
        setSelectedStudent(Number(currentUserData.parentDetail.students[0].student_id))
      } else {
        setSelectedStudent(Number(student_id))
        if (epic275story5395) {
          const selectedStudentData = studentsWithApplicationNotRemoved.find(
            // @ts-ignore
            (student) => Number(student.student_id) === student_id,
          )
          if (epic275TASK6023) {
            assingStudentInitialNotes(selectedStudentData?.admin_notes)
          } else {
            setStudentNotes(selectedStudentData?.admin_notes)
          }
        }
      }
      if (currentUserData.parentDetail.person.user.userRegions.length) {
        setApplicationState(currentUserData.parentDetail.person.user.userRegions[0].regionDetail.name)
      } else if (currentUserData.parentDetail.person?.address?.state) {
        setApplicationState(currentUserData.parentDetail.person.address.state)
      }
      if (!selectedStudent) {
        setSelectedParent(parseInt(currentUserData.parentDetail.parent_id))
      }
    }
  }, [currentUserData])

  const { data: currentStudentData, refetch: refetchStudent } = useQuery(getStudentDetail, {
    variables: {
      student_id: selectedStudent,
    },
    skip: !selectedStudent,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (currentStudentData) {
      const { student, student_school_year } = currentStudentData
      setStudent(student)
      setStudentSchoolYears(student_school_year)
      setStudentOldStatus(currentStudentData.student?.status.at(-1)?.status)
    }
  }, [currentStudentData])

  const [updateStatus] = useMutation(updateScheduleStatus)
  const [approveApplication] = useMutation(approveApplicationMutation)

  const approveApplicationAction = async (applicationId: number | string, currentStatus: string) => {
    await approveApplication({
      variables: {
        acceptApplicationInput: {
          application_ids: [String(applicationId)],
          fromAdmin: true,
          currentStatus: currentStatus,
        },
      },
    })
  }

  const setSOE = useCallback(
    async (newSchoolPartnerId: string, isTransfer = false, generateTransferForm = false) => {
      if (!epic275Story6022) {
        setLoading(true)
      }
      try {
        if (
          !isNil(newSchoolPartnerId) &&
          newSchoolPartnerId !== (epic275Story6022 ? schoolPartnerIdInitial : schoolPartnerId)
        ) {
          const studentData = {
            studentId: studentStatus.student_id,
            name: `${student?.person.last_name}, ${student?.person.first_name}`,
          }
          if (isTransfer) {
            await transferStudentsSOE(
              newSchoolPartnerId,
              studentStatus.school_year_id,
              [String(studentStatus.student_id)],
              [studentData],
              generateTransferForm,
            )
          } else {
            await assignStudentsSOE(
              newSchoolPartnerId,
              studentStatus.school_year_id,
              [String(studentStatus.student_id)],
              [studentData],
            )
          }
        }
      } catch (error) {
        console.error('Error assigning an SOE to student')
        console.error(error)
      }
      setSchoolPartnerIdInitial(newSchoolPartnerId)
      setSchoolPartnerId(newSchoolPartnerId)
      if (!epic275Story6022) {
        setLoading(false)
      }
    },
    [schoolPartnerId, studentStatus, student, assignStudentsSOE, transferStudentsSOE],
  )

  const updateStatusScheduleAction = async (scheduleId: number, status: string) => {
    await updateStatus({
      variables: {
        scheduleId,
        status,
      },
    })
  }

  const [savePacket] = useMutation(savePacketMutation)
  const approveEnrollmentPacketAction = async (packet_id: number | string) => {
    await savePacket({
      variables: {
        enrollmentPacketInput: {
          packet_id: Number(packet_id),
          status: EnrollmentPacketSaveAction.ACCEPTED,
          save_context: EnrollmentPacketSaveContext.USER_PROFILE_ACTION,
        },
      },
    })
  }

  const handleSavePerson = async () => {
    setLoading(true)
    if (soeChange && epic275Story6022) {
      setSOE(schoolPartnerId, isTransfer, generateTransferForm)
    }
    if (selectedParent) {
      const person: PersonProfile = Object.assign({}, userInfo as PersonProfile)
      delete person.address
      delete person.phone
      person.person_id = Number(person.person_id)
      const phone: PhoneProfile = Object.assign({}, phoneInfo as PhoneProfile)
      phone.phone_id = Number(phone.phone_id)
      delete person.user
      const address = Object.assign({}, (userInfo as PersonProfile).address)
      delete person.uniqueId
      address.address_id = address.address_id ? Number(address.address_id) : undefined
      await updatePersonAddress({
        variables: {
          updatePersonAddressInput: {
            parent_id: selectedParent ? Number(selectedParent) : undefined,
            address: address,
            phone: phone,
            person: person,
            notes: notes,
          },
        },
      })

      await saveNotificationSetting({
        variables: {
          personId: Number(person.person_id),
          notificationType: selectedSetting,
        },
      })

      const { errors } = await updateStudentsAddress({
        variables: {
          updateStudentsAddressInput: {
            address: address,
            person_id: Number(person.person_id),
          },
        },
      })

      if (errors) return

      setRequesting(false)
      if (epic275Story5565) {
        setAlertSetting({
          show: true,
          message: 'Changes saved successfully',
          type: 'success',
        })
        handleReload()
      } else handleClose(true)
    } else {
      const person: PersonProfile = Object.assign({}, studentPerson as PersonProfile)
      delete person.address
      delete person.phone
      person.person_id = Number(person.person_id)
      person.date_of_birth = studentStatus.birth
      const phone: PhoneProfile = Object.assign({}, phoneInfo as PhoneProfile)
      phone.phone_id = Number(phone.phone_id)
      const address: AddressProfile = Object.assign({}, (studentPerson as PersonProfile).address)
      address.address_id = Number(address.address_id)
      delete person.uniqueId

      await updatePersonAddress({
        variables: {
          updatePersonAddressInput: {
            address: address,
            phone: phone,
            person: person,
          },
        },
      })

      await updateStudent({
        variables: {
          updateStudentInput: {
            student_id: studentStatus?.student_id ? studentStatus?.student_id : null,
            grade_level: studentStatus?.student_id ? String(studentStatus?.grade_level) : null,
            special_ed: studentStatus?.student_id ? studentStatus?.special_ed : null,
            diploma_seeking: studentStatus?.student_id ? studentStatus?.diploma_seeking : null,
            status:
              studentStatus?.student_id && studentStatus.status !== StudentStatus.WITHDRAWN
                ? studentStatus?.status
                : null,
            school_year_id: studentStatus?.student_id ? studentStatus?.school_year_id : null,
            testing_preference: studentStatus?.student_id ? studentStatus?.testing_preference : null,
            date: studentStatus?.student_id ? studentStatus?.date : null,
            ...(epic275story5395 ? { admin_notes: studentNotes } : {}),
          },
        },
      }).catch((error) => {
        console.error(error)
        // This helps to avoid the page from continuing to load when the mutation fails.
      })

      const isCurrentAccepted = (studentStatus?.student_id ? studentStatus?.status : null) === StudentStatus.ACCEPTED
      const isCurrentPending = (studentStatus?.student_id ? studentStatus?.status : null) === StudentStatus.PENDING
      const isCurrentActive = (studentStatus?.student_id ? studentStatus?.status : null) === StudentStatus.ACTIVE

      if (
        isCurrentAccepted &&
        (studentOldStatus === StudentStatus.APPLIED || studentOldStatus === StudentStatus.REAPPLIED)
      ) {
        //Accept application here
        const curStudent = currentStudentData?.student
        if (curStudent?.applications.length > 0) {
          const curApplicatonID = curStudent?.applications?.at(-1)?.application_id
          approveApplicationAction(curApplicatonID, StudentStatus.ACCEPTED.toString())
        }
      } else if (isCurrentPending && studentOldStatus === StudentStatus.ACCEPTED) {
        //Accept enrollment packets
        const curStudent = currentStudentData?.student
        if (curStudent?.packets.length > 0) {
          const curPacketID = curStudent?.packets[0].packet_id
          approveEnrollmentPacketAction(curPacketID)
        }
      }

      if (isCurrentActive && studentOldStatus === StudentStatus.REAPPLIED) {
        if (currentStudentData.student?.applications.length > 0) {
          const curApplicatonID = currentStudentData.student?.applications?.at(-1)?.application_id
          approveApplicationAction(curApplicatonID, StudentStatus.ACTIVE.toString())
        }
      }

      if (isCurrentPending && studentOldStatus === StudentStatus.REAPPLIED) {
        if (actualSchedule?.scheduleId) {
          updateStatusScheduleAction(actualSchedule?.scheduleId, ScheduleStatus.NOT_SUBMITTED)
        }
      }

      if (typeof studentStatus?.diploma_seeking === 'number' && studentStatus?.school_year_id) {
        await saveDiplomaAnswer({
          variables: {
            saveDiplomaAnswerInput: {
              answer: studentStatus?.diploma_seeking,
              studentId: studentStatus?.student_id,
              schoolYearId: studentStatus?.school_year_id,
            },
          },
        }).catch((error) => {
          console.error(error)
          // This helps to avoid the page from continuing to load when the mutation fails.
        })
      }

      if (studentStatus?.withdrawOption) {
        await createWithdrawal({
          variables: {
            withdrawalInput: {
              withdrawal: {
                StudentId: studentStatus?.student_id,
                school_year_id: studentStatus?.school_year_id,
                status:
                  studentStatus?.withdrawOption === WithdrawalOption.NOTIFY_PARENT_OF_WITHDRAW
                    ? WithdrawalStatus.NOTIFIED
                    : WithdrawalStatus.WITHDRAWN,
                date_effective:
                  studentStatus?.withdrawOption !== WithdrawalOption.NOTIFY_PARENT_OF_WITHDRAW
                    ? startOfDay(new Date()).toISOString()
                    : null,
              },
              withdrawalOption: Number(studentStatus?.withdrawOption),
            },
          },
        })
      }

      if (studentStatus?.activeOption) {
        await deleteWithdrawal({
          variables: {
            studentId: studentStatus?.student_id,
            activeOption: studentStatus?.activeOption,
            schoolYearId: studentStatus?.school_year_id,
          },
        })
      }

      if (
        studentStatus?.inactiveOption &&
        Number(studentStatus?.inactiveOption) === InactiveOption.NOTIFY_PARENT_OF_INACTIVE
      ) {
        await sendInactiveEmailUpdate({
          variables: {
            studentId: studentStatus?.student_id,
            schoolYearId: studentStatus?.school_year_id,
          },
        })
      }

      setRequesting(false)
      if (epic275Story5565) {
        setAlertSetting({
          show: true,
          message: 'Changes saved successfully',
          type: 'success',
        })
        handleReload()
      } else handleClose(true)
    }
    setLoading(false)
  }
  const handleCloseObserverModal = () => {
    setOpenObserverModal(false)
    refetch()
  }

  //TODO: Check observer logic to refactor portion of code, observer_id does not exist in the query
  // @ts-ignore
  const handleChangeParent = (parent) => {
    setSelectedStudent(0)
    if (parent?.observer_id) {
      setSelectedParent(parseInt(parent.observer_id))
      setUserInfo(parent.person)
      setPhoneInfo(parent.person.phone)
      setAddressInfo(parent.person.address)
      setNotes(parent.notes || EMPTY_STRING)
      setStudents(
        currentUserData.parentDetail.students.filter((x: { student_id: number }) => x.student_id == parent.student_id),
      )
      setUserRole(RoleLabels.OBSERVER)
    } else if (currentUserData?.parentDetail) {
      setSelectedParent(parseInt(currentUserData.parentDetail.parent_id))
      setUserInfo(currentUserData.parentDetail.person)
      setPhoneInfo(currentUserData.parentDetail.phone)
      setAddressInfo(currentUserData.parentDetail.person.address)
      setNotes(currentUserData.parentDetail.notes)
      setParentEmail(currentUserData.parentDetail.person.email)
      setStudents(currentUserData.parentDetail.students)
      setUserRole(RoleLabels.PARENT)
    }
    if (epic275story5395) {
      if (epic275TASK6023) {
        assingStudentInitialNotes(getStudentNotesForParent())
      } else {
        setStudentNotes(getStudentNotesForParent())
      }
    }
  }

  useEffect(() => {
    if (!epic275TASK6023) return
    if (!initialStudentNotesSeted) {
      setInitialStudentNotesSeted(true)
    }
    if (initialStudentNotesSeted && stripHtmlTags(studentNotes) !== stripHtmlTags(initialStudentNotes)) {
      setIsChanged(true)
    }
  }, [studentNotes])

  const changeStudentAction = async (student: Student) => {
    if (epic275Story6022) {
      setSoeChange(false)
    }
    if (epic275TASK6023) {
      setIsChanged(false)
      setInitialStudentNotesSeted(false)
    }
    setSelectedParent(0)
    await setSelectedStudent(Number(student.student_id))
    if (epic275story5395) {
      setNotes(currentUserData.parentDetail.notes)
      if (epic275TASK6023) {
        assingStudentInitialNotes(student.admin_notes)
      } else {
        setStudentNotes(student.admin_notes)
      }
      setIsEditedByExternal(!isEditedByExternal)
    }
    refetchStudent()
    setUserRole(RoleLabels.STUDENT)
  }

  const handleChangeStudent = async (student: Student) => {
    if (!epic275TASK6023) {
      return changeStudentAction(student)
    }
    if (isChanged) {
      setStudentToMove(student)
      setConfirmLeaveWithouthSave(true)
    } else {
      await changeStudentAction(student)
    }
  }

  const handleReload = () => {
    setIsFromSave(true)
    refetch()
    refetchStudent()
    reload()
  }

  if (reloading) return <></>

  return (
    <Card sx={classes.content}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <Header
          userData={currentUserData?.parentDetail?.person}
          setOpenObserverModal={setOpenObserverModal}
          observers={observers}
          // @ts-ignore
          handleChangeParent={handleChangeParent}
          selectedParent={selectedParent}
          parentId={currentUserData?.parentDetail?.parent_id}
          me={me!}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {!requesting && (
            <Button
              sx={{
                background: MthColor.BUTTON_LINEAR_GRADIENT,
                textTransform: 'none',
                color: 'white',
                marginRight: 2,
                width: '92px',
                height: '25px',
              }}
              onClick={() => {
                setIsChanged(false)
                setRequesting(true)
                handleSavePerson()
              }}
            >
              Save
            </Button>
          )}
          <CloseIcon
            style={{ color: 'white', background: MthColor.BLACK, borderRadius: 2, cursor: 'pointer' }}
            onClick={() => handleClose(false)}
          />
        </Box>
      </Box>
      <MthLoadingWrapper
        loading={loading && phoneAvailableLoading && loadingSaveNotificationSettings && notificationSettingLoading}
      >
        <Students
          students={students}
          parentId={parent_id}
          selectedStudent={selectedStudent!}
          handleChangeStudent={handleChangeStudent}
          me={me!}
          currentSchoolYear={schoolYear}
          nextSchoolYear={nextSchoolYear}
          userRole={userRole}
        />
        {userRole === RoleLabels.PARENT ? (
          // @ts-ignore
          <ParentProfile
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            phoneInfo={phoneInfo}
            setPhoneInfo={setPhoneInfo}
            notes={notes}
            setNotes={setNotes}
            applicationState={applicationState}
            // @ts-ignore
            familyStudentIds={map(students, (familyMember) => Number(familyMember.student_id))}
            studentNotes={studentNotes}
            setStudentNotes={setStudentNotes}
            notificationSettings={notificationSettings}
            selectedSetting={selectedSetting}
            setSelectedSetting={setSelectedSetting}
            phoneAvailability={phoneAvailability}
          />
        ) : userRole === RoleLabels.STUDENT ? (
          <StudentProfile
            studentId={selectedStudent}
            setStudentPerson={setStudentPerson}
            setStudentStatus={setStudentStatus}
            studentStatus={studentStatus}
            setActualSchedule={setActualSchedule}
            actualSchedule={actualSchedule}
            applicationState={applicationState}
            setIsChanged={setIsChanged}
            phoneInfo={phoneInfo as unknown as Phone}
            addressInfo={addresInfo as unknown as Address}
            notes={notes}
            setNotes={setNotes}
            setPhoneInfo={setPhoneInfo}
            student={student}
            studentSchoolYears={studentSchoolYears}
            refetchStudent={refetchStudent}
            currentSchoolYear={schoolYear}
            nextSchoolYear={nextSchoolYear}
            // @ts-ignore
            familyStudentIds={map(students, (familyMember) => Number(familyMember.student_id))}
            studentNotes={studentNotes}
            setStudentNotes={setStudentNotes}
            isEditedByExternal={isEditedByExternal}
            schoolPartnerIdInitial={schoolPartnerIdInitial}
            setSchoolPartnerIdInitial={setSchoolPartnerIdInitial}
            setSchoolPartnerId={setSchoolPartnerId}
            schoolPartnerId={schoolPartnerId}
            setSoeChange={setSoeChange}
            setIsTransfer={setIsTransfer}
            setGenerateTransferForm={setGenerateTransferForm}
            setSOE={setSOE}
            soeChange={soeChange}
          />
        ) : (
          <></>
        )}
      </MthLoadingWrapper>
      {openObserverModal && (
        <NewUserModal
          handleModem={handleCloseObserverModal}
          visible={openObserverModal}
          students={students}
          parent_id={parent_id!}
          ParentEmailValue={parentEmail!}
          regionId={me?.selectedRegionId}
          schoolYearId={schoolYear.school_year_id}
          currentRegion={me?.userRegion?.find((region) => region.region_id === me?.selectedRegionId)}
        />
      )}
      {epic275TASK6023 && confirmLeaveWithouthSave && (
        <CustomModal
          title={MthTitle.UNSAVED_TITLE}
          description={MthTitle.UNSAVED_DESCRIPTION}
          cancelStr='Cancel'
          confirmStr='Yes'
          backgroundColor={MthColor.WHITE.toString()}
          onClose={() => {
            setConfirmLeaveWithouthSave(false)
          }}
          onConfirm={() => {
            if (studentToMove) changeStudentAction(studentToMove)
            setConfirmLeaveWithouthSave(false)
            setStudentToMove(undefined)
          }}
        />
      )}
      {/*remove when epic275Story5565 is deleted*/}
      {!epic275Story5565 && (
        <Snackbar
          open={errorFlag}
          autoHideDuration={5000}
          onClose={() => setErrorFlag(false)}
          message='Error'
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          sx={classes.snackbar}
        />
      )}
    </Card>
  )
}
