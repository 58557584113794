export const NoneValue = 0 // this help to set the default value of the person intervention

export enum PersonInterventionLabels {
  NONE = 'None',
  MISSING_LAST_LOG = 'Missing Last Log',
  MISSING_LAST_TWO_LOGS = 'Missing Last Two Logs',
  AT_RISK = 'At-Risk',
  WORKING_WITH_PARENT_SUPPORT = 'Working with Parent Support',
  PLANS_TO_WITHDRAW = 'Plans to Withdraw',
  WATCH_LIST = 'Watch List',
  WORKING_WITH_DIPLOMA_SEEKING_SPECIALISTS = 'Working with Diploma Seeking Specialists',
  ASSESSMENT_INTERVENTIONS = 'Assessment Interventions',
  SCHEDULE_REQUIRED = 'Schedule Required',
}

export enum INTERVENTION_STATUS {
  MISSING_LAST_LOG = 1,
  MISSING_LAST_TWO_LOGS,
  AT_RISK,
  WORKING_WITH_PARENT_SUPPORT,
  PLANS_TO_WITHDRAW,
  WATCH_LIST,
  WORKING_WITH_DIPLOMA_SEEKING_SPECIALISTS,
  ASSESSMENT_INTERVENTIONS,
  SCHEDULE_REQUIRED,
}
